/**
 * Created by Wanna on 2017/3/29.
 */
(function (factory) {
    if ("undefined" == typeof jQuery) throw new Error("jQuery not loaded");
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports === 'object') {
        factory(require('jquery'));
    } else {
        factory(jQuery);
    }
})(function ($) {
    var $document = $(document);
    $('.header-off').click(function(){
        $('.header-ad').hide();
    });
    function MOVIES() {

    }

    MOVIES.prototype.tabs = function () {

        var $container = $('[data-tabs]');

        function _tabs(element) {
            this.element = $(element);
            this.support = this.element.is('[data-tabs]');
            if (this.support) {
                this.className = 'active';
                this.init();
            }
        }
        _tabs.prototype = {
            init: function () {
                this.$tab = this.element.find('.' + this.className);
                this.$link = this.$tab.find('a');
                this.$panel = $('[data-id="' + this.$link.attr('href') + '"]').addClass(this.className);
                this.$tabBtn = this.element.find('[data-tabs-list],li');
                this.addListener();
            },
            addListener: function () {
                this.$tabBtn.on('click', $.proxy(this.startTab, this));
            },
            startTab: function (e) {
                e.preventDefault();
                var $currentTarget = $(e.currentTarget);
                this.$link = $currentTarget.find('a');
                this.id = this.$link[0].hash;
                if (this.id && !this.$tab.is(this.className)) {
                    this.$panel.removeClass(this.className);
                    this.$tab.removeClass(this.className);
                    this.$tab = $currentTarget.addClass(this.className);
                    this.$panel = $('[data-id="' + this.id + '"]').addClass(this.className);
                    $container.trigger('tabs.change', this);
                }
            }
        };
        $container.each(function () {
            var tabs = new _tabs(this);
        });
        return new _tabs();
    }();

    MOVIES.prototype.slider = function () {
        function _slider() {
            this.support = !!$('.bxslider[data-count]').length;
            if (this.support) this.init();
        }

        _slider.prototype = {
            init: function () {
                this.addListener();
                this.getDefult();
            },
            addListener: function () {
                $document.on('click', '.bx-next', $.proxy(this.next, this));
                $document.on('click', '.bx-prev', $.proxy(this.prev, this));
            },
            setControls: function () {
                var controls = '<a class="bx-next" href="">Next</a><a class="bx-prev" href="">Prev</a>';
                this.$controls.html(controls);
            },
            getDefult: function () {
                this.$active = $('.bxslider');
                this.$list = this.$active.find('.bxslider-list');
                this.$controls = this.$active.find('.controls');
                this.count = this.$active.data().count;
                this.status = false;
                this.current = 0;
                this.setControls();
            },
            next: function (event) {
                event.preventDefault();
                event.stopPropagation();
                if (!this.status) {
                    this.status = true;
                    this.current += 4;
                    if (this.current + 4 >= this.count) this.current = this.count - 4;
                    var _this = this;
                    this.$list.animate({left: -(this.current * 160)}, 500, function () {
                        _this.status = false;
                    });
                }
            },
            prev: function (event) {
                event.preventDefault();
                event.stopPropagation();
                if (!this.status) {
                    this.status = true;
                    this.current -= 4;
                    if (this.current <= 0) this.current = 0;
                    var _this = this;
                    this.$list.animate({left: -(this.current * 160)}, 500, function () {
                        _this.status = false;
                    });
                }
            }
        };

        return new _slider();
    }();

    MOVIES.prototype.trailers = function () {
        function _trailers() {
            this.$body = $('.detail');
            this.$preview = this.$body.find('.preview');
            this.support = !!this.$preview.length;
            this.className = '[data-tag]';
            if (this.support) this.init();
        }

        _trailers.prototype = {
            init: function () {
                this.addListener();
            },
            addListener: function () {
                $document.on('click', this.className, $.proxy(this.setting, this));
            },
            setting: function (event) {
                this.$action = $(event.currentTarget);
                var data = this.$action.data();
                this.url = data.code;
                this.type = data.tag;
                this.title = this.$body.find('.poster-body .title').text();
                this.ThirdPartyConnection = this.$body.find('.poster-body a').attr('src');
                this.updatePreview();
            },
            htmlTmpl: function () {
                return {
                    'v': '<ins><a href="' + this.ThirdPartyConnection + '" target="_blank">Watch ' + this.title +
                    ' on Amazon Channel，<em class="orange">Start Your 30-Day Free Trial</em>!</a></ins><iframe src="' + this.url + '" frameborder="0" allowfullscreen></iframe>',
                    'p': '<img src="' + this.url + '" alt="">'
                };
            },
            updatePreview: function () {
                this.$preview.html(this.htmlTmpl()[this.type]);
            }
        };

        return new _trailers();
    }();

    var movies = new MOVIES();
});